













































































































































import { Component, Vue } from 'vue-property-decorator'
import { apiSelffetchShopList,apiSelffetchVerifierList } from '@/api/shop'
import { apiPlatformZtOrder } from '@/api/order/order'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import DatePicker from '@/components/date-picker.vue'

@Component({
  components: {
    LsDialog,
    LsPagination,
    ExportData,
    DatePicker
  }
})
export default class SelffetchShop extends Vue {
  /** S Data **/
  apiPlatformZtOrder = apiPlatformZtOrder
  // 表单数据
  form = {
    shop_id: '',
    pickup_code: '',
    hx_state: '',
    order_sn: '',
    user_info: '',
    start_time: '',
    end_time: '',
    hx_user: '',
    store_name: ''
  }
  shopList: any[] = []
  SelfVerifierList: any[]=[]
  pager: RequestPaging = new RequestPaging()
  /** E Data **/

  /** S Methods **/
  // 搜索
  search() {
    this.pager.page = 1
    this.getHxOrderList()
  }

  // 重置搜索
  resetSearch() {
    Object.keys(this.form).map(key => {
      this.$set(this.form, key, '')
    })
    this.getHxOrderList()
  }

  // 获取列表数据
  getHxOrderList() {
    // 请求管理员列表
    this.pager
        .request({
          callback: apiPlatformZtOrder,
          params: this.form
        }).then(res => {
      console.log(res)
    })
        .catch(() => {
          this.$message.error('数据请求失败，刷新重载!')
        })
  }

  // 订单详情
  goSelffetchOrderDetail(id: number) {
    this.$router.push({
      path: '/trade/order_detail',
      query: { id: id + '' }
    })
  }

  getShopLists() {
    apiSelffetchShopList({
      page_type: 0
    }).then(res => {
        // console.log(res)
      this.shopList = res
    })
  }

  getSelfVerifierList(){
    apiSelffetchVerifierList({
      page_type: 0
    }).then(res => {
      // console.log(res)
      this.SelfVerifierList = res
    })
  }

  /** E Methods **/

  /** S Life Cycle **/
  created() {
    this.getHxOrderList()
    this.getShopLists()
    this.getSelfVerifierList()
  }

  /** E Life Cycle **/
}
