













import { Component, Vue } from "vue-property-decorator";
import ztOrderList from "./ztOrder.vue";
import hxOrderList from "./hxOrder.vue";
@Component({
  components: {
    ztOrderList,
    hxOrderList,
  },
})
export default class Order extends Vue {
  // S Data
  activeName: any = "ztOrderList";

  created() {
    this.activeName = this.$route.query.activeName ? this.$route.query.activeName :'ztOrderList'
  }

  // E life cycle
}
