





































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import ExportData from '@/components/export-data/index.vue'
import { RequestPaging } from '@/utils/util'
import { apigetWriteOffInfo, apiPlatformWriteOrder } from '@/api/order/order'
import {apiGetSupplier} from '@/api/shop'
import DatePicker from '@/components/date-picker.vue'
import lsPagination from "@/components/ls-pagination.vue";

@Component({
  components: {
    ExportData,
    DatePicker,
    lsPagination
  }
})
export default class WriteOff extends Vue {
  queryObj = {
    order_sn: '',
    code: '',
    state: '',
    start_time: '',
    end_time: '',
    writeoff_type:''
  }
  tabCount = {
    all_count: 0,
    platform_audit_count: 0,
    sales_count: 0,
    sellout_count: 0,
    storage_count: 0,
    warning_count: 0
  }
  handleDetail(row: any) {
    this.$router.push({
      path: '/trade/writeoff-order-detail',
      query: {
        id: row.id
      }
    })
  }

  options: any[] = [];
  pager = new RequestPaging()
  activeName: any = 'all'

  /*async getSupplierList(): Promise<void> {
    const res = await apiGetSupplier({
      app_id: this.queryObj.app_id,
    });
    this.options = res;
  }*/


  apiPlatformWriteOrder = apiPlatformWriteOrder
  getList(page?: number): void {
    page && (this.pager.page = page)
    this.pager
        .request({
          callback: apiPlatformWriteOrder,
          params: {
            // type: GoodsType[this.activeName],
            ...this.queryObj
          }
        })
        .then((res: any) => {
          this.tabCount = res?.extend
        })
    // this.getSupplierList()
  }
  handleReset() {
    this.queryObj = {
      order_sn: '',
      code: '',
      state: '',
      start_time: '',
      end_time: ''
    }

    this.getList()
  }

  created() {
    this.getList()
  }
}
